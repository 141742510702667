import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'shared/config/routes'
import { CookiesService } from 'shared/services/Cookies.service'

export const useIsAcceptedCaptcha = () => {
  const navigate = useNavigate()

  const cookie = CookiesService.get({ name: 'acceptedCaptcha' })
  const isCookiePresent = !!cookie

  useEffect(() => {
    if (!isCookiePresent) {
      return navigate(ROUTES.CAPTCHA)
    }
  }, [isCookiePresent])
}
