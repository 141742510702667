import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useAppContext from 'app/store/useAppContext'
import { ROUTES } from 'shared/config/routes'
import { CookiesService } from 'shared/services/Cookies.service'

export const useIsNoAnswers = () => {
  const navigate = useNavigate()

  const cookie = CookiesService.get({ name: 'acceptedCaptcha' })
  const isCookiePresent = !!cookie

  const {
    store: {
      user: { questions },
    },
  } = useAppContext()

  useEffect(() => {
    if (!questions?.q1?.answer && !questions?.q10?.answer && isCookiePresent) {
      navigate(`${ROUTES.QUESTIONS}/1`)
    }
  }, [questions])
}
