import classNames from 'classnames'

import useAppContext from 'app/store/useAppContext'
import { AffirmationForm } from 'features/affirmation/form/form'
import { useIsNoAnswers } from 'features/questions'
import { getCurrentDate } from 'shared/utils/functions'
import { useIsAcceptedCaptcha } from 'shared/lib'
import InvestImg from 'shared/assets/images/contacts/InvestImg.webp'

import cls from './Contacts.module.scss'

export const ContactsPage = () => {
  useIsNoAnswers()
  useIsAcceptedCaptcha()

  const {
    store: { isMobile500, user },
    dispatch,
  } = useAppContext()

  const currentDate = getCurrentDate()

  return (
    <>
      <section className={cls.page}>
        <div
          className={classNames('container__desktop_full', cls.page_container)}
        >
          <div className={cls.container}>
            <div className={cls.content}>
              <div className={cls.left_block}>
                <AffirmationForm
                  title="Оставьте Ваши контакты, чтобы открыть инвест-счет"
                  user={user}
                  dispatch={dispatch}
                  isMobile500={isMobile500}
                />
              </div>
              <div className={cls.right_block}>
                <div className={cls.gift_desc}>
                  <div className={cls.gift_desc__block1}>
                    <p>Спасибо за ответы!</p>
                  </div>
                  <div className={cls.gift_desc__block2}>
                    <p>ВАШ ПОДАРОК</p>
                    <p>600 000 СУМ</p>
                  </div>
                  <div className={cls.gift_desc__block3}>
                    <p>Получите пакет акций при открытии счета</p>
                    <p>
                      Сделайте первое пополнение до {currentDate}, чтобы
                      получить бонус!
                    </p>
                  </div>
                </div>
                <div className={cls.gift_image}>
                  <img src={InvestImg} alt="InvestImg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
