import { Chat } from 'features/questions'
import { useIsAcceptedCaptcha } from 'shared/lib'

import cls from './ChatPage.module.scss'

export const ChatPage = () => {
  useIsAcceptedCaptcha()

  return (
    <div className={cls.page}>
      <div className={cls.container}>
        <div className={cls.chat}>
          <div className={cls.chat_container}>
            <Chat />
          </div>
        </div>
        <div className={cls.label}>
          <p>Made by USM Gold</p>
        </div>
      </div>
    </div>
  )
}
