import { NavLink } from 'react-router-dom'
import cl from 'classnames'
import { Stream } from '@cloudflare/stream-react'

import useAppContext from 'app/store/useAppContext'
import { ROUTES } from 'shared/config/routes'
import { videoIdTokenHome } from 'shared/config/videoIdOrSignedToken'
import { useIsAcceptedCaptcha } from 'shared/lib'
import { Button } from 'shared/ui'
import MetalloinvestIcon from 'shared/assets/icons/partners/Metalloinvest.webp'
import MegafonIcon from 'shared/assets/icons/partners/Megafon.webp'
import MailRuIcon from 'shared/assets/icons/partners/MailRu.webp'
import KommersantIcon from 'shared/assets/icons/partners/Kommersant.webp'

import { CardInfo } from './CardInfo/CardInfo'
import { GiftPromotion } from './GiftPromotion/GiftPromotion'

import cls from './HomePage.module.scss'

export const HomePage = () => {
  useIsAcceptedCaptcha()

  const {
    store: { isMobile500 },
  } = useAppContext()

  return (
    <section className={cl('flex__col_center', cls.page)}>
      <div className={cl('container__desktop_outside', cls.page__container)}>
        <div className={cls.left_block}>
          <div>
            <h1 className={cls.title}>
              <p>
                ИНВЕСТИРУЙТЕ <span>В ЗОЛОТО</span> И
              </p>
              <p>ПОЛУЧАЙТЕ ДОХОД</p>
              <p>
                ОТ <span>6 000 000 UZS</span> В МЕСЯЦ
              </p>
            </h1>

            <p className={cls.desc}>
              Ответьте на 10 вопросов, чтобы получить бонус к Вашему
              инвестиционному счету!
            </p>

            {isMobile500 && <CardInfo classname={cls.card} />}
          </div>

          <NavLink to={isMobile500 ? ROUTES.CHAT : `${ROUTES.QUESTIONS}/1`}>
            <Button>Пройти опрос</Button>
          </NavLink>
        </div>

        <div className={cls.right_block}>
          <div className={cls.video}>
            <Stream
              controls
              src={videoIdTokenHome}
              autoplay={true}
              width={'100%'}
              muted={true}
            />
          </div>
        </div>
      </div>

      {!isMobile500 && (
        <>
          <div
            className={cl(
              'container__desktop_outside',
              cls.partners__container,
            )}
          >
            <div className={cls.partners}>
              <p className={cls['partners__title']}>Партнеры:</p>
              <div className={cls['partners__icons']}>
                <img src={MetalloinvestIcon} alt="MetalloinvestIcon" />
                <img src={MegafonIcon} alt="MegafonIcon" />
                <img src={MailRuIcon} alt="MailRuIcon" />
                <img src={KommersantIcon} alt="KommersantIcon" />
              </div>
            </div>
          </div>

          <div
            className={cl('container__desktop_outside', cls.gift__container)}
          >
            <GiftPromotion />
          </div>
        </>
      )}
    </section>
  )
}
